@use '../base/vars' as *;
.newspage{
    
    .newspage_inner{
        max-width: 840px;
        height: auto;
        //min-height: 780px;
        margin: 0 auto;
        
        @include media_query(){
            max-width: 100%;
        }
        
        
        .newspage_list{
            margin-top: 60px;
            
            @include media_query(){
                margin-top: 40px;
            }
            
            .newspage_list_body{
                list-style: none;
                
                @include media_query(){
                    padding: 0 30px;
                }
                
                li{
                    padding: 40px 0;
                    border-top: solid 1px;
                    border-color: #9d9c9c;
                    display: flex;
                    
                    @include media_query(){
                        text-align: left;
                        padding: 20px 0;
                        display: block;
                    }
                    
                    &:last-child{
                        margin-bottom: 60px;
                        border-bottom: solid 1px;
                        border-color: #9d9c9c;
                    }
                    
                    time{
                        margin-right: 60px;
                        color: #333;
                        line-height: 2;
                        @include rem(16);
                        
                        @include media_query(){
                            display: block;
                            margin-bottom: 8px;
                            @include rem(14);
                            line-height: 1.88;
                        }
                    }
                    
                    a{
                        text-decoration: none;
                        color: #4e6d84;
                        @include rem(17);
                        line-height: 2;
                        flex: 1;
                        
                        
                        @include media_query(){
                            @include rem(16);
                            line-height: 1.88;
                        }
                    }
                }
            }
        }
    }
}