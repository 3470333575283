@use '../base/vars' as *;

.firstview{
    width: 100vw;
    height: 100vh;
    background-image: url(../img/3.jpg);
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    h2{
        width: 100%;
    }

    .logomark{
        width: 100%;
        text-align: center;
        
        animation:100s linear infinite rotation;
        
        img{
            width: 100%;
            max-width: 315px;
            height: auto;
            
            @include media_query(){
                max-width: 177px;
            }
        }
    }
    
    .logotype{
        width: 100%;
        margin-top: 35px;
        text-align: center;
        
        @include media_query(){
            margin-top: 30px;
        }
        
        img{
            width: 100%;
            max-width: 452px;
            height: auto;
            
            @include media_query(){
                max-width: 250px;
            }
        }
    }
    
    @keyframes rotation{
        0%{ transform:rotate(0);}
        100%{ transform:rotate(-360deg); }
    }

    p{
        @include rem(30);
        font-weight: normal;
        margin-top: 1em;
        line-height: 1.5;
        letter-spacing: 7.5px;
        color: #fff;
        
        @include media_query(){
            @include rem(14);
            letter-spacing: 2.8px;
        }
    }
    
}

.section_title{
    display: flex;
    flex-direction: column;
    align-items: center;
    @include rem(60);
    line-height: 1.5;
    
    @include media_query(){
        @include rem(27);
    }
    
    span{
        @include rem(20);
        font-weight: 500;
        
        @include media_query(){
            @include rem(14);
        }
    }
}

.concept{
    padding-top: 150px;
    padding-bottom: 80px;
    
    @include media_query(){
        padding-top: 100px;
        padding-bottom: 60px;
    }
    
    .concept_inner{
        margin: 0 auto;
        width: 100%;
        max-width: 1000px;
        
        @include media_query(){
            max-width: 100%;
            padding: 0 30px;
        }
        
        .concept_inner_box{
            width: 100%;
            padding: 60px 20px 80px;
            background-image: url(../img/168.png);
            background-repeat: no-repeat;
            background-position: center bottom;
            background-size: cover;
            text-align: center;
            
            .section_title{
                color: #fff;
            }
            
            .concept_inner_body{
                margin-top: 60px;
                @include rem(17);
                color: #fff;
                line-height: 2;
                
                @include media_query(){
                    @include rem(15);
                }
            }
        }
        
        .concept_body{
            margin-top: 80px;
            text-align: center;
            color: #333;
            
            @include media_query(){
                margin-top: 60px;
            }
            
            .concept_body_top{
                font-weight: bold;
                @include rem(30);
                line-height: 1.5;
                
                @include media_query(){
                    @include rem(20);
                }
            }
            
            .concept_body_main{
                margin-top: 40px;
                @include rem(17);
                line-height: 2;
                
                @include media_query(){
                    @include rem(16);
                }
            }
        }
    }    
}

.news{
    background-image: url(../img/news_bg.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 673px;
    
    @include media_query(){
        background-position: top;
        background-size: 100% auto;
        padding: 100px 30px 0 30px;
    }
    
    .news_inner{
        max-width: 1000px;
        height: auto;
        //min-height: 780px;
        margin: 0 auto;
        padding: 60px 180px 80px;
        background-color: #f1f4f3;
        
        @include media_query(){
            max-width: 100%;
            padding: 30px 15px 30px;
        }
        
        
        .news_list{
            margin-top: 60px;
            
            p{
                text-align: center;
                @include rem(18);
            }
            
            .news_list_body{
                list-style: none;
                
                li{
                    padding: 40px 0;
                    border-top: solid 1px;
                    border-color: #9d9c9c;
                    
                    @include media_query(){
                        text-align: left;
                        padding: 20px 0;
                    }
                    
                    &:last-child{
                        margin-bottom: 60px;
                        border-bottom: solid 1px;
                        border-color: #9d9c9c;
                    }
                    
                    time{
                        margin-right: 60px;
                        color: #333;
                        @include rem(16);
                        
                        @include media_query(){
                            display: block;
                            margin-bottom: 8px;
                            @include rem(14);
                        }
                    }
                    
                    a{
                        text-decoration: none;
                        color: #4e6d84;
                        @include rem(17);
                        line-height: 2;
                        
                        @include media_query(){
                            @include rem(16);
                            line-height: 1.88;
                        }
                    }
                }
            }
        }
    }
}

.flyer{
    padding-top: 140px;
    
    @include media_query(){
        padding-top: 100px;
    }
    
    .flyer_inner{
        
        
        .flyer_list{
            max-width: 1125px;
            margin: 0 auto;
            padding: 0 20px;
            
            @include media_query(){
                padding: 0;
            }
            
            .flyer_list_body{
                margin-top: 80px;
                display: flex;
                flex-wrap: wrap;
                margin-left: -54px;
                list-style: none;
                
                @include media_query(){
                    flex-direction: column;
                    justify-content: center;
                    margin-left: 0px;
                    margin-top: 40px;
                    padding: 0 30px;
                }
                
                li{
                    width: calc(33.333% - 54px);
                    margin-left: 54px;
                    
                    @include media_query(){
                        width: 100%;
                        margin-left: 0;
                    }
                    
                    .flyer_img{
                        display: block;
                        text-decoration: none;
                        
                        img{
                            //max-width: 335px;
                            box-shadow: 0 10px 30px 0 rgba(51, 51, 51, 0.3);
                            width: 100%;
                            height: auto;
                            vertical-align: bottom;
                            
                            @include media_query(){
                                max-width: 100%;
                            }
                        }
                        
                        p{
                            max-width: 335px;
                            margin-top: 40px;
                            margin-bottom: 80px;
                            @include rem(17);
                            line-height: 2;
                            color: #333;
                            
                            @include media_query(){
                                max-width: 100%;
                                margin-top: 30px;
                                margin-bottom: 60px;
                                @include rem(16);
                            }
                        }
                    }
                }
            }
        }
    }
}




.step{
    margin-top: 160px;
    
}

.apply{
    margin-top: 160px;
    background-image: url(../img/22106242-l.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 673px;
    
    @include media_query(){
        background-position: top;
        background-size: 100% auto;
        padding: 100px 30px 0 30px;
    }
    
    .apply_inner{
        max-width: 1000px;
        height: auto;
        min-height: 780px;
        margin: 0 auto;
        padding: 60px 180px 80px;
        background-color: #f1f4f3;
        
        @include media_query(){
            max-width: 100%;
            padding: 60px 15px 80px;
        }
        
        
        .apply_table{
            margin: 0 auto;
            margin-top: 60px;
            .apply_table_body{
                .apply_table_title{
                    width: 280px;
                    height: auto;
                    border: solid 2px #9d9c9c;
                    background-color: #4e6d84;
                    color: #fff;
                    @include rem(20);
                    padding-top: 30px;
                    padding-bottom: 30px;
                    
                }
                
                .apply_table_main{
                    width: 560px;
                    border: solid 2px #9d9c9c;
                    background-color: #fff;
                    padding-left: 30px;
                    padding-right: 30px;
                    line-height: 1.47;
                    @include rem(17);
                    
                    a{
                        color: #4e6d84;
                    }
                }
            }
        }
        
        
        .apply_comment{
            text-align: center;
            
            p{
                margin-top: 60px;
                margin-bottom: 30px;
                @include rem(30);
                font-weight: bold;
                color: #333;
            }
        }
    }
}




.banners{
    max-width: 1000px;
    margin: 160px auto 0;
    
    @include media_query(){
        margin: 70px 0 0;
        padding: 0 30px;
    }
    
    .banners_list{
        ul{
            list-style: none;
            
            & + ul{
                margin-top: 45px;
            }
            
            li{
                background-color: #fff;
                box-shadow: 0 20px 30px 0 rgba(51, 51, 51, 0.3);
                
                a{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    text-align: center;
                    
                    img{
                        width: auto;
                        height: auto;
                        max-width: 100%;
                        max-height: 100%;
                        object-fit: contain;
                        vertical-align: bottom;
                    }
                }
            }
        }
        
        .banners_large{
            display: flex;
            flex-wrap: wrap;
            margin-left: -45px;
            
            @include media_query(){
                flex-direction: column;
                margin: 0;
            }
            
            li{
                width: calc(33.3333% - 45px);
                margin-left: 45px;
                
                @include media_query(){
                    width: 100%;
                    margin: 30px 0 0 0;
                }
                
                &:not(:nth-child(-n+3)){
                    margin-top: 45px;
                    
                    @include media_query(){
                        margin-top: 30px;
                    }
                }
            }
        }
        
        .banners_middle{
            display: flex;
            flex-wrap: wrap;
            margin-left: -40px;
            
            @include media_query(){
                flex-direction: column;
                margin: 0;
            }
            
            li{
                width: calc(25% - 40px);
                margin-left: 40px;
                
                @include media_query(){
                    width: 100%;
                    margin: 30px 0 0 0;
                }
                
                &:not(:nth-child(-n+4)){
                    margin-top: 45px;
                    
                    @include media_query(){
                        margin-top: 30px;
                    }
                }
            }
        }
    }
}





.interview{
    max-width: 1280px;
    margin: 0 auto;
    padding: 160px 0 0;
    
    @include media_query(){
        margin: 0 auto;
        padding: 100px 0 0;
    }
    
    .interview_contents{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 300px);
        
        @include media_query(){
            display: block;
        }
        
        &.notpost{
            grid-template-columns: 1fr;
            grid-template-rows: auto;
        }
        
        & + .btn_pink{
            margin-top: 80px;
            
            @include media_query(){
                margin-top: 60px;
            }
        }
        
        .interview_head{
            // grid-area: head;
            grid-column: 1 / 2;
            grid-row: 1 / 3;
            background-color: $main-color;
            color: #fff;
            padding: 200px 100px;
            
            @include media_query(){
                padding: 60px 30px;
            }
            
            .section_title{
                align-items: flex-start;
            }
            
            p{
                @include rem(16);
                margin-top: 60px;
                line-height: 2;
                
                @include media_query(){
                    margin-top: 30px;
                }
            }
        }
        
    }
}

.interview_item{
    display: block;
    position: relative;
    transition: opacity .4s;
    text-decoration: none;
    
    &:hover{
        opacity: .7;
    }
    
    &:nth-of-type(2){
        //grid-area: item;
        grid-column: 2 / 4;
        grid-row: 1 / 3;
        
        @include media_query(){
            grid-column: auto;
            grid-row: auto;
        }
    }
    
    &:not(:nth-of-type(2)){
        //grid-area: other;
    }
    
    .interview_item_img{
        width: 100%;
        height: 100%;
        
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            vertical-align: bottom;
        }
    }
    
    .interview_item_main{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 25px 40px;
        
        @include media_query(){
            position: static;
            background-color: $main-color;
        }
        
        time{
            @include rem(17);
            line-height: 1.78;
            color: #fff;
            text-shadow: 0 1px 6px rgba(51, 51, 51, 0.3);
            
            @include media_query(){
                @include rem(14);
            }
        }
        
        h3{
            @include rem(18);
            line-height: 1.78;
            color: #fff;
            text-shadow: 0 1px 6px rgba(51, 51, 51, 0.3);
            
            @include media_query(){
                @include rem(16);
            }
        }
    }
    
}