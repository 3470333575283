@use '../base/vars' as *;


.interviewpage_list{
    max-width: 1125px;
    margin: 80px auto 0;
    padding: 0 20px;
    
    @include media_query(){
        padding: 0;
        margin-top: 0;
    }
    
    .interviewpage_list_body{
        margin-top: 80px;
        display: flex;
        flex-wrap: wrap;
        margin-left: -54px;
        list-style: none;
        
        @include media_query(){
            flex-direction: column;
            justify-content: center;
            margin-left: 0px;
            margin-top: 40px;
            padding: 0 30px;
        }
        
        li{
            width: calc(33.333% - 54px);
            margin-left: 54px;
            
            @include media_query(){
                width: 100%;
                margin-left: 0;
            }
            
            .interviewpage_img{
                display: block;
                box-shadow: 0 10px 30px 0 rgba(51, 51, 51, 0.3);
                
                img{
                    //max-width: 335px;
                    width: 100%;
                    height: 150px;
                    object-fit: cover;
                    vertical-align: bottom;
                    
                    @include media_query(){
                        max-width: 100%;
                    }
                }
            }
            
            time{
                display: flex;
                font-weight: bold;
                margin-top: 40px;
                @include rem(16);
                
                @include media_query(){
                    margin-top: 30px;
                }
            }
            
            p{
                max-width: 335px;
                font-weight: bold;
                margin-bottom: 70px;
                @include rem(17);
                line-height: 2;
                
                @include media_query(){
                    max-width: 100%;
                    margin-bottom: 50px;
                    @include rem(16);
                }
            }
        }
    }
}