@charset "UTF-8";

//*------------------------------------*//
//	Base
//*------------------------------------*//

@use 'base/reset';
@use 'base/default';






//*------------------------------------*//
//	page
//*------------------------------------*//
@use 'page/common';
@use 'page/index';
@use 'page/flyer';
@use 'page/page';
@use 'page/news';
@use 'page/newspost';
@use 'page/interview';
@use 'page/interviewpost';

@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@400;500;700;900&display=swap');