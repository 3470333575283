@use '../base/vars' as *;

.page_head{
    width: 100%;
    height: 670px;
    
    background-image: url(../img/22106242-l.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    margin-bottom: 80px;
    
    
    @include media_query(){
        height: 400px;
        margin-bottom: 40px;
    }
    
    // .logo{
    //     margin-top: 50px;
        
    //     img{
    //         width: 100%;
    //         max-width: 250px;
    //         height: auto;
            
    //         @include media_query(){
    //             max-width: 200px;
    //         }
    //     }
    // }
    .logomark{
        margin-top: 50px;
        width: 100%;
        text-align: center;
        
        animation:100s linear infinite rotation;
        
        img{
            width: 100%;
            max-width: 175px;
            height: auto;
            
            @include media_query(){
                max-width: 140px;
            }
        }
    }
    
    .logotype{
        width: 100%;
        margin-top: 35px;
        text-align: center;
        
        @include media_query(){
            margin-top: 20px;
        }
        
        img{
            width: 100%;
            max-width: 250px;
            height: auto;
            
            @include media_query(){
                max-width: 200px;
            }
        }
    }
    
    @keyframes rotation{
        0%{ transform:rotate(0);}
        100%{ transform:rotate(-360deg); }
    }
}

.pagenotfound{
    .notfound{
        padding-top: 200px;
        max-width: 600px;
        margin: 0 auto;
        
        @include media_query(){
            padding-top: 100px;
        }
        
        text-align: center;
        line-height: 2;
        
        h2{
            @include rem(80);
            font-weight: bold;
            letter-spacing: .2em;
            color: #000;
            
            @include media_query(){
                @include rem(60);
            }
        }
        
        p{
            @include rem(20);
        }
        
        & + .btn_pink{
            margin-top: 60px;
        }
    }
}