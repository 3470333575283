@use '../base/vars' as *;



.newspost_body{
    margin: 80px auto 0;
    height: auto;
    max-width: 980px;
    background-color: #f1f4f3;
    padding: 80px;
    
    @include media_query(){
        max-width: 315px;
        padding: 40px 20px;
        margin-top: 40px;
    }

    h1{
        @include rem(30);
        line-height: 1.73;
        
        @include media_query(){
            @include rem(20);
            line-height: 1.5;
        }
    }
    
    p{
        @include rem(17);
        line-height: 2;
        margin-top: 40px;
        
        @include media_query(){
            @include rem(16);
            margin-top: 20px;
            line-height: 1.5;
        }
    }



    h2{
        @include rem(24);
        line-height: 1.5;
        margin-top: 60px;
        border-bottom: solid;
        border-color: #4e6d84;
        padding-bottom: 10px;
        
        @include media_query(){
            margin-top: 40px;
        }
    }
    
    p{
        @include rem(17);
        line-height: 2;
        
        @include media_query(){
            @include rem(16);
            margin-top: 20px;
            line-height: 1.5;
        }
    }
    
    ul{
        margin-top: 36px;
        margin-left: 20px;
        
        @include media_query(){
            margin-top: 30px;
        }
        
        li{
            @include rem(17);
            line-height: 2;
        }
    }
    
    ol{
        margin-top: 36px;
        margin-left: 20px;
        
        @include media_query(){
            margin-top: 30px;
        }
        
        li{
            @include rem(17);
            line-height: 2;
        }
    }


    
    h3{
        @include rem(20);
        line-height: 1.5;
        margin-top: 60px;
        color: #4e6d84;
        max-width: 820px;
        height: auto;
        background-color: #fff;
        padding: 13px 25px;
        font-weight: bold;
        
        @include media_query(){
            margin-top: 40px;
            padding: 15px;
        }
    }
    
    
    a{
        @include rem(17);
        color: #4e6d84;
        
        @include media_query(){
            @include rem(16);
        }
    }
    
}

