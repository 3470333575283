@use '../base/vars' as *;

html,
body{
    font-size: .8em;
    height: 100%;
}

body{
    background-image: url(../img/dot.png);
    background-size: 10px;
}

.wrap{
    height: 100%;
    display: flex;
    flex-direction: column;
}

.header{
    position: fixed;
    left: 0;
    right: 0;
    top: -100%;
    z-index: 99;
    opacity: 0;
    padding: 0 20px;
    
    transition-property: top, opacity;
    transition-duration: 1s;

    .header_inner{
        width: 100%;
        max-width: 1280px;
        height: 100px;
        margin: 0 auto;
        background-color: #fff;
        box-shadow: 0 25px 30px 0 rgba(51, 51, 51, 0.2);
        display: flex;
        align-items: center;
        
        @include media_query(){
            //max-width: 345px;
            height: 60px;
            box-shadow: 0 10px 20px 0 rgba(51, 51, 51, 0.2);
            padding: 0 17px;
        }

        .logo{
            padding: 0 40px;
            margin-right: auto;
            
            @include media_query(){
                padding: 0;
            }
            
            a{
                transition: opacity .25s;
                &:hover{
                    opacity: .7;
                }
            }
            
            img{
                width: 100%;
                max-width: 150px;
                height: auto;
                
                @include media_query(){
                    max-width: 100px;
                }
            }
        }

        .navigation{
            margin-right: 60px;
            
            @include media_query(){
                visibility: hidden;
                opacity: 0;
                
                position: fixed;
                top: 0;
                left: 0;
                z-index: 999;
                width: 100%;
                height: 100%;
                background-color: #f1f4f3;
                padding: 35px 30px;
                
                transition-property: visibility, opacity;
                transition-duration: .4s;
            }

            ul{
                display: flex;
                align-items: center;
                
                @include media_query(){
                    flex-direction: column;
                    justify-content: center;
                    margin-top: 30px;
                }

                li{
                    & + li{
                        margin-left: 60px;
                        
                        @include media_query(){
                            margin: 20px 0 0 0;
                        }
                    }

                    a{
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        @include rem(22);
                        font-weight: 700;
                        text-decoration: none;
                        color: $font-color;
                        
                        transition: opacity .25s;
                        &:hover{
                            opacity: .7;
                        }

                        span{
                            font-weight: normal;
                            @include rem(14);
                            margin-top: 3px;
                        }
                    }
                }
            }
            
            .sp_navigation_head{
                display: none;
                
                @include media_query(){
                    display: block;
                }
                
                .sp_navigation_logo{
                    img{
                        width: 100%;
                        max-width: 115px;
                        height: auto;
                    }
                }
                
                .drawer_close{
                    position: absolute;
                    right: 22px;
                    top: 27px;
                    
                    img{
                        width: 47px;
                        height: auto;
                    }
                }
            }
            
            .sp_navigation_foot{
                display: none;
                margin-top: 25px;
                
                @include media_query(){
                    display: block;
                }
            }
            
            .sp_contact{
                margin-top: 35px;
                color: $main-color;
                
                h3{
                    @include rem(20);
                    margin-bottom: 10px;
                }
                
                p{
                    @include rem(16);
                    margin-bottom: 13px;
                    
                    & + p{
                        @include rem(14);
                        line-height: 2;
                        
                        a{
                            color: $main-color;
                        }
                    }
                }
            }
            
            .privacy{
                @include rem(16);
                font-weight: 500;
                
                a{
                    color: $main-color;
                }
            }
            
            .btn_entry_sp{
                a{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 60px;
                    background-color: #e06464;
                    @include rem(18);
                    font-weight: 700;
                    color: #fff;
                    text-decoration: none;
                }
            }
            
            &.open{
                visibility: visible;
                opacity: 1;
            }
        }

        .bnt_entry{
            flex: 1;
            width: 100%;
            max-width: 314px;
            height: 100%;
            
            @include media_query(){
                display: none;
            }

            a{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                background-color: $accent-color;
                text-decoration: none;
                color: #fff;
                @include rem(20);
                font-weight: 700;
                
                transition: opacity .25s;
                &:hover{
                    opacity: .7;
                }
            }
        }
        
        .drawer_open{
            display: none;
            
            @include media_query(){
                display: block;
            }
        }
    }
}

.inview{
    top: 30px;
    opacity: 1;
    
    @include media_query(){
        top: 20px;
    }
}

.main{
    flex: 1;
}

.footer{
    background-color: $main-color;
    margin-top: 160px;
    
    @include media_query(){
        margin-top: 100px;
    }
    
    .footer_inner{
        position: relative;
        max-width: 1320px;
        margin: 0 auto;
        padding: 60px 20px 40px;
        color: #fff;

        display: flex;
        
        @include media_query(){
            display: block;
        }

        .footer_info{
            .footer_logo{
                margin-bottom: 35px;

                img{
                    width: 100%;
                    max-width: 180px;
                    height: auto
                }
            }

            .contact{
                h3{
                    @include rem(22);
                    margin-bottom: 10px;
                    
                    @include media_query(){
                        @include rem(20);
                    }
                }

                p{
                    @include rem(17);
                    
                    @include media_query(){
                        @include rem(16);
                    }

                    a{
                        color: #fff;
                        text-decoration: none;
                        
                        transition: opacity .25s;
                        &:hover{
                            opacity: .7;
                        }
                    }

                    & + p{
                        margin-top: 12px;
                        @include rem(16);
                        line-height: 2;
                        
                        @include media_query(){
                            @include rem(14);
                        }
                    }
                }
            }
        }

        .footer_banner{
            align-self: flex-end;
            margin-left: auto;
            
            @include media_query(){
                margin-top: 60px;
            }

            .footer_banner_list{
                display: flex;
                margin-bottom: 60px;
                
                @include media_query(){
                    margin-bottom: 0;
                }

                li{
                    list-style: none;
                    
                    & + li{
                        margin-left: 25px;
                    }

                    a{
                        transition: opacity .25s;
                        &:hover{
                            opacity: .7;
                        }
                        
                        img{
                            width: auto;
                            max-height: 70px;
                        }
                    }
                }
            }

            .footer_nav{
                
                @include media_query(){
                    margin-top: 20px;
                }
                
                p{
                    text-align: right;
                    a{
                        color: #fff;
                        transition: opacity .25s;
                        &:hover{
                            opacity: .7;
                        }
                    }
                }
            }

            .copy{
                text-align: right;
                @include rem(16);
                margin-top: 1em;
                
                @include media_query(){
                    @include rem(14);
                }
            }
        }
    }
}




.scrolltop{
    position: absolute;
    right: 20px;
    top: -25px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 58px;
    height: 58px;
    background-color: #fff;
    border: solid 2px $main-color;
    box-shadow: 0 10px 30px 0 rgba(51, 51, 51, 0.3);
    border-radius: 50%;

    &:hover{
        &::before{
            margin-top: -5px;
        }
    }

    &::before{
        display: block;
        content: "";
        left: 0;
        top: 0;
        margin: 5px 0 0 0;
        width: 15px;
        height: 15px;
        border-top: 3px solid $main-color;
        border-right: 3px solid $main-color;
        transform: rotate(-45deg);

        transition: margin .25s;
    }
}

.btn_pink{
    
    a{
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 360px;
        height: 60px;
        margin: 0 auto;
        color: #ffffff;
        box-shadow: 0 10px 30px 0 rgba(51, 51, 51, 0.3);
        background-image: linear-gradient(to right, #e06464, #fc758c 104%);
        border-radius: 80px;
        text-align: center;
        text-decoration: none;
        
        @include rem(18);
        text-align: center;
        text-decoration: none;
        letter-spacing: normal;
        white-space: nowrap;
        color: #ffffff;
        
        
        @include media_query(){
            @include rem(16);
            width: 255px;
            height: 60px;
            box-shadow: 0 5px 20px 0 rgba(51, 51, 51, 0.3);
        }
        
        transition: opacity .25s;
        &:hover{
            opacity: .7;
        }
    }
}

.btn_blue{
    margin-top: 80px;
    
    @include media_query(){
        margin-top: 40px;
    }
    
    a{
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 360px;
        height: 60px;
        margin: 0 auto;
        color: #ffffff;
        box-shadow: 0 10px 30px 0 rgba(51, 51, 51, 0.3);
        background-color: #4e6d84;
        border-radius: 80px;
        text-align: center;
        text-decoration: none;
        
        @include rem(18);
        text-align: center;
        text-decoration: none;
        letter-spacing: normal;
        white-space: nowrap;
        color: #ffffff;
        
        
        @include media_query(){
            @include rem(16);
            width: 255px;
            height: 60px;
            box-shadow: 0 5px 20px 0 rgba(51, 51, 51, 0.3);
        }
        
        transition: opacity .25s;
        &:hover{
            opacity: .7;
        }
    }
}

.pagenation{
    display: flex;
    margin-top: 80px;
    margin-bottom: 80px;
    justify-content: center;
    align-items: center;
    
    @include media_query(){
        margin-top: 40px;
        margin-bottom: 40px;
    }
    
    .btn_prev{
        a{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 160px;
            height: 60px;
            margin-right: 30px;
            color: #2e639b;
            box-shadow: 0 10px 30px 0 rgba(51, 51, 51, 0.3);
            background-color: #ffffff;
            border: solid 3px #4e6d84;
            border-radius: 80px;
            text-align: center;
            text-decoration: none;
            @include rem(16);
            background-image: url(../img/icon_prev.png);
            background-position: left 20px center;
            background-repeat: no-repeat;
            background-size: 35px 35px;
            
            transition: opacity .25s;
            &:hover{
                opacity: .7;
            }
            @include media_query(){
                width: 135px;
                height: 60px;
            }
        }
    }
    
    .page{
        margin: 0 20px;
        @include rem(17);
        @include media_query(){
            margin: 0 10px;
        }
    }
    
    .btn_next{
        a{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 160px;
            height: 60px;
            color: #ffffff;
            box-shadow: 0 10px 30px 0 rgba(51, 51, 51, 0.3);
            background-color: #4e6d84;
            border-radius: 80px;
            text-align: center;
            text-decoration: none;
            @include rem(16);
            background-image: url(../img/icon_next.png);
            background-position: right 20px center;
            background-repeat: no-repeat;
            background-size: 35px 35px;
            
            transition: opacity .25s;
            &:hover{
                opacity: .7;
            }
            
            @include media_query(){
                width: 135px;
                height: 60px;
            }
        }
    }
}